import { useHubspotForm, HubSpotFormLocale } from '@aaronhayes/react-use-hubspot-form';
import { Box, Center, CircularProgress, BoxProps } from "@chakra-ui/react";
import { FC } from "react";
import { logEvent } from "src/lib/analytics";
import { buildPageProperties } from "src/lib/event/properties";

import { buildOptionalFormParams, convertToId, hasLanguage, tweakFormId } from "./utils";

// The Portal ID valid for the whole Hubspot project
const HUBSPOT_PORTAL_ID = '26730884';

type DatoCmsHubspotFormIntegrationProps = {
  formId: string; // Form ID available on Hubspot
  recordId: string; // Record ID for the Hubspot Form record available on DatoCMS
  inlineMessage?: string;
  redirectUrl?: string;
} & BoxProps;

/**
 * This component exists for parity with the same feature from the shine.fr-reborn repository,
 * which cannot enforce a required formId because of Prismic.
 * Actually on DatoCMS, even though we made `formId` required, the GraphQL type generation has `Maybe`
 * It is due to the way our version of `gatsby-source-datocms` works
 * @see https://www.datocms.com/docs/content-delivery-api/api-endpoints#strict-mode-for-non-nullable-graphql-types
 */
const DatoCmsHubspotFormIntegration: FC<DatoCmsHubspotFormIntegrationProps> = (props) => {
  const { formId: formIdTemp, inlineMessage, recordId, redirectUrl: redirectUrlProp } = props;

  const formId = tweakFormId(formIdTemp);

  // Because Hubspot Form creation is based on HTML id, we try and make it unique
  const inlineMessageId = convertToId(inlineMessage);
  const redirectUrlId = convertToId(redirectUrlProp);
  const stringId = [inlineMessageId, redirectUrlId, formId, recordId].filter((id) => !!id).join('-');
  const targetId = `hubspot-form-${stringId}`;
  const target = `#${targetId}`;

  const locale = hasLanguage('fr') ? HubSpotFormLocale.FRENCH : HubSpotFormLocale.ENGLISH;

  /**
   * To ensure that Segment has time to receive the event,
   * rather than relying on Hubspot API fully for the custom redirect,
   * we use the track callback function
   */
  const optionalFormParamsTemp = buildOptionalFormParams(inlineMessage, redirectUrlProp);
  const { redirectUrl } = optionalFormParamsTemp;
  const hasRedirectUrl = !!redirectUrl;
  const optionalFormParams = hasRedirectUrl ? {} : optionalFormParamsTemp;

  const onFormSubmitted = (_form: any, data: any) => {
    const submissionValues = data?.submissionValues ?? {};
    const {
      email,
      firstname,
      lastname,
      phone
    } = submissionValues;

    const event = {
      name: 'Lead Form Submitted',
      properties: {
        ...(email ? { email } : {}),
        ...(firstname ? { firstname } : {}),
        ...(lastname ? { lastname } : {}),
        ...(phone ? { phone } : {}),
        ...buildPageProperties()
      }
    };

    if (hasRedirectUrl) {
      logEvent(event, () => window.location.assign(redirectUrl));
    } else {
      logEvent(event);
    }
  };

  const formParams = {
    portalId: HUBSPOT_PORTAL_ID,
    formId,
    formInstanceId: targetId,
    target,
    locale,
    onFormSubmitted,
    ...optionalFormParams
  };

  const { loaded, error, formCreated } = useHubspotForm(formParams);
  const formIsAvailable = loaded && !error && formCreated;

  if (error) {
    return null;
  }

  return formIsAvailable ? (
    <Box
      bg="shine.yellow"
      borderRadius="3xl"
      maxW={{ base: "400px", md: "full" }}
      mx="auto"
      my={{ base: "10" }}
      p={{ base: 6, md: 8 }}
      spacing={{ base: 4, md: 6 }}
      {...props}
    >
      <div id={targetId} />
    </Box>
  ) : (
    <Center flexDirection="column" minHeight="300px" paddingY="space-128">
      <CircularProgress isIndeterminate />
    </Center>
  );
};

export default DatoCmsHubspotFormIntegration;
